<template>
  <div class="mx-4">
    <div class="flex flex-row items-center justify-center">
      <div class="flex flex-col justify-center">
        <h6 class="text-center text-base font-bold lg:text-lg">
          We can offer more deals near you!
        </h6>
        <span v-if="isDesktop" class="text-center">
          Please allow access to your location
        </span>
        <button
          type="button"
          class="mt-4 rounded-full bg-red-dark p-3 text-xs font-bold capitalize text-white shadow lg:text-base"
          @click="onButtonClick"
        >
          {{ buttonText }}
        </button>
      </div>
      <div class="ml-3">
        <HhImage
          :src="hold"
          :use-mutator="false"
          alt="allow location access"
          width="200"
          :style="isDesktop ? 'width: 244px;' : 'width: 285px;'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { type PropType, computed } from "vue";
import hold from "~/assets/image/hh-mascot-hold-phone.png";
import useClientTypeStore from "~/stores/clientType";
const emits = defineEmits<{
  (e: "on-allow"): void;
  (e: "on-change-location"): void;
  (e: "on-request-user-location"): void;
}>();
const props = defineProps({
  permission: {
    type: String as PropType<"denied" | "granted" | "prompt">,
    required: true,
  },
});
const { isDesktop } = useClientTypeStore();
const { t } = useI18n();

const buttonText = computed(() => {
  return props.permission !== "denied" ? t("allow") : t("changeLocation");
});

function onButtonClick() {
  if (!isDesktop) {
    emits("on-request-user-location");
    return;
  }

  if (props.permission !== "denied") {
    emits("on-allow");
    return;
  }
  emits("on-allow");
  // emits("on-change-location");
}
</script>
<script lang="ts">
export default {
  name: "NearMeRequestLocation",
};
</script>

<i18n lang="json">
{
  "en": {
    "allow": "Allow location access"
  },
  "th": {
    "allow": "อนุญาต"
  }
}
</i18n>
